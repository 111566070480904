import { api } from "@helpers/api";
import { IUser } from "@/types/IUser";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const StackedBarChart = () => {
  interface CallData {
    user: IUser
    answered: number;
    not_answered: number;
  }

  const [data, setData] = useState<CallData[]>([])

  const getCalls = async () =>
    await api.get('/crm/sdr/callsToday').then(e => {
      setData(e.data)
    })

  useEffect(() => {
    getCalls();
    const interval = setInterval(() => {
      getCalls()
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  data?.sort(
    (a, b) => b.answered + b.not_answered - (a.answered + a.not_answered)
  );

  const filteredData = {
    labels: data?.map(item => item.user.name),
    datasets: [
      {
        label: 'Answered Calls',
        data: data?.map(item => item.answered),
        backgroundColor: 'rgba(27, 98, 242, 1)',
        stack: 'stack0',
        borderWidth: 1,
      },
      {
        label: 'Unanswered Calls',
        data: data?.map(item => item.not_answered),
        backgroundColor: 'rgba(232, 100, 82, 1)',
        stack: 'stack0',
        borderWidth: 1,
      },
    ],
    customEmails: data?.map(item => item.user.email.split('@opendatacenter.com.br')[0]),
  };

  const userImage = {
    id: 'userImage',
    afterDatasetsDraw(chart) {
      const { ctx } = chart

      filteredData.customEmails.forEach((image, index) => {
        const xPos = chart.getDatasetMeta(0).data[index].x
        const yPos = chart.getDatasetMeta(0).data[index].y
        const chartImage = new Image()

        chartImage.src = `https://painel.opendatacenter.com.br/assets/img/avatars/${image}.png`;

        chartImage.onload = () => {
          const radius = 13;
          const circleX = xPos - radius / 2;
          const circleY = yPos - radius / 2 - 7;

          ctx.save();
          ctx.beginPath();
          ctx.arc(circleX + radius, circleY + radius, radius, 0, Math.PI * 2);
          ctx.clip();

          ctx.drawImage(chartImage, circleX, circleY, radius * 2, radius * 2);

          ctx.restore();
        };
      })
    }
  }


  const blueLabel = {
    id: 'blueLabel',
    afterDatasetsDraw(chart) {
      const { ctx } = chart
      filteredData.customEmails.forEach((email, index) => {
        const meta0 = chart.getDatasetMeta(0);
        const bar = meta0.data[index];
        const xPosition = bar.x - 17
        const yPosition = bar.y + 5;

        ctx.font = 'bold 12px sans-serif'
        ctx.fillStyle = 'white'
        ctx.textAlign = 'center'

        ctx.fillText(filteredData.datasets[0].data[index], xPosition, yPosition);
      })
    }
  }

  const redLabel = {
    id: 'redLabel',
    afterDatasetsDraw(chart) {
      const { ctx } = chart
      filteredData.customEmails.forEach((_, index) => {
        const meta0 = chart.getDatasetMeta(0);
        const bar = meta0.data[index];
        const xPosition = bar.x + 30
        const yPosition = bar.y + 5;

        ctx.font = 'bold 12px sans-serif'
        ctx.fillStyle = 'white'
        ctx.textAlign = 'center'

        ctx.fillText(filteredData.datasets[1].data[index], xPosition, yPosition);
      })
    }
  }

  const total = {
    id: 'total',
    afterDatasetsDraw(chart) {
      const { ctx } = chart
      filteredData.customEmails.forEach((_, index) => {
        const meta0 = chart.getDatasetMeta(1);
        const bar = meta0.data[index];
        const xPosition = bar.x + 30
        const yPosition = bar.y + 5;
        const total = filteredData.datasets[1].data[index] + filteredData.datasets[0].data[index]

        ctx.font = 'bold 12px sans-serif'
        ctx.fillStyle = 'white'
        ctx.textAlign = 'center'
        ctx.fillText(total, xPosition, yPosition);
      })
    }
  }

  const metaLine = {
    id: 'metaLine',
    afterDatasetsDraw(chart) {
      const { ctx, scales: { x }, chartArea: { left, right } } = chart

      ctx.save()
      ctx.beginPath()
      ctx.moveTo(x.getPixelForValue(50), left - 220)
      ctx.lineTo(x.getPixelForValue(50), right + 100)
      ctx.strokeStyle = 'rgba(22, 255, 8, 1)'
      ctx.lineWidth = 3
      ctx.stroke()
    }
  }

  const options = {
    type: 'bar',
    indexAxis: 'y',
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grace: 0
      },
      y: {
        beginAtZero: true,
        stacked: true,
        min: 0,
        ticks: {
          color: 'white',
          padding: 15,
        },
      },
    },
    plugins: {
      legend: {
        display: false
      },
    },
  };

  return (
    <div style={{
      height: '100vh',
      maxHeight: '100vh',
      background: "black"
    }}>
      <Bar data={filteredData} options={options} plugins={[userImage, metaLine, blueLabel, redLabel, total]} />
    </div>
  )
};

export default StackedBarChart;

